<template>
  <div>
    <iframe class="iFrameFormat"
      :src="`${baseUrl}/checkout?authKey=${iFrameToken}&clinicId=${clinicId}`" title="POS">
    </iframe>
  </div>
</template>

<script>
import { PAYMENTS_BASE_URL } from './constants';


export default {
  props: [
    "clinicId"
  ],
  data: () => ({
    iFrameToken: "",
    baseUrl: PAYMENTS_BASE_URL
  }),
  methods: {
  },

  async created() {
    this.iFrameToken = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`
    );
  },
};
</script>

<style scoped>
.iFrameFormat {
  border: 0;
  width: 100%;
  height: 100vh;
}
</style>