export const getPosUrl = () => {
    /** US */
    const baseUrl = process.env.VUE_APP_PAYMENTS_BASE_URL

    if (process.env.VUE_APP_REGION !== 'US') {
        return `${baseUrl}.au`;
    }

    return baseUrl;

}


