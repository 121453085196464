<template>
  <div>
      <div>
        <iframe class=" iFrameFormat"
          :src="`${baseUrl}/` +
              `${currentRoute}?authKey=${iFrameToken}&` +
              `organizationId=${organizationId}&clinicId=${clinicId}&` +
              `pos-tab=${activeTab}&pos-origin=${currentUrl}`"
          title="POS">
          </iframe>
      </div>
  </div>  
</template>

<script>

import PosTransactions from '../../clinic/pos/PosTransactions.vue';
import PosCheckout from '../../clinic/pos/PosCheckout.vue';
import PosFees from '../../clinic/pos/PosFees.vue';
import PosReaders from '../../clinic/pos/PosReader.vue';
// import PosDashboard from '../../clinic/pos/PosDashboard.vue';
import { mapActions } from "vuex";
import { getPosUrl } from '../../clinic/pos/helpers'


export default {
  components: {
      PosTransactions,
      PosCheckout,
      PosFees,
      PosReaders,
      // PosDashboard
  },
  inject: ['getRoleRouteName'],
  data() {
      return {
          iFrameToken: "",
          organizationId: null,
          clinics: [],
          clinicId: "",
          activeTab: "pos-readers",
          baseUrl: getPosUrl(),
          isPOSOnboardingCompleted: false,
          currentTab: "",
          currentRoute:"",
          currentUrl:""
      }
  },
  methods: {
      ...mapActions("clinic", [
          "fetchPOSEnabledClinics"
      ]),
      async checkIsPOSEnabled() {
          let { data } = await this.$store.dispatch('admin/fetchUserPermission', {
              userId: this.$store.state.AppActiveUser.id,
              organizationId: this.organizationId,
          });
          const hasPOSEnabled = data.some(e => e.permission.name === "POS");
          if (!hasPOSEnabled) {
              const [defaultRoute, _] = this.$route.matched;
              this.$router.push({path: defaultRoute.path});
          }
      },
      receiveMessage(event) {
          if (event.origin === this.baseUrl) {
              if(event.data.action === "change-tab"){
                localStorage.setItem("pos-active-tab", event.data.data)
              }
          }
      },
  },
  mounted() {
      window.addEventListener('message', this.receiveMessage, false);
  },
  beforeDestroy() {
      window.removeEventListener('message', this.receiveMessage, false);
  },
  async created() {
      this.activeTab = localStorage.getItem('pos-active-tab') || 'pos-readers'
      this.currentUrl = `${window.location.href}`;

      if (this.activeTab == "pos-dashboard") {
        this.currentRoute = "dashboard"
      } else if(this.activeTab == "pos-checkout") {
        this.currentRoute = "checkout"
      } else if(this.activeTab == "pos-transaction") {
        this.currentRoute = "transactions"
      } else {
        this.currentRoute = "settings/terminals"
      }

      this.organizationId = localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      );
     
      this.iFrameToken = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`
      );
      this.checkIsPOSEnabled();
  }
};
</script>

<style>
.pos-tabs .con-ul-tabs {
  height: 40px;
  overflow-x: auto;
  overflow-y: hidden;
}

.pos-tabs .con-ul-tabs::-webkit-scrollbar {
  display: none;
}

.content-wrapper{
  background-color: transparent;
}
.iFrameFormat {
border: 0;
width: 100%;
height: 100vh;
}

.pos-tabs button span{
  font-weight: 600 !important;
}
</style>
